<template>
    <v-container>
        <!-- <v-skeleton-loader
        class="ma-4"
        ref="skeleton"
        type="card-avatar"
        v-if="this.isFirstLoading">
        </v-skeleton-loader> -->
        <v-row
        v-if="this.isFirstLoading">
            <v-col class="mx-auto"
            cols="12"
            sm="6">
                <v-btn
                color="primary"
                @click="()=>{
                    this.$router.go(-1);
                }">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>
                <v-toolbar
                class="secondary white--text text-h5 mt-3">
                    <span >
                        Proof of payment form
                    </span>
                </v-toolbar>
                <AError
                :api="this.api"/>
                <!-- <v-card class=""
                v-if="this.$store.getters.getRole=='vision_admin'">
                    <v-container>
                        <v-col>
                            <div class="d-flex justify-start mb-5">
                                    {{ company.name }}
                            </div>
                            <div class="my-1">
                                <v-text-field
                                label="Salesperson :"
                                v-model="company.salesperson_name"
                                disabled>
                                </v-text-field>
                            </div>
                            <div class="my-1">
                                <v-text-field
                                label="Total payment (RM) :"
                                v-model="paymentInfo.total_amount">
                                </v-text-field>
                            </div>
                            <div class="my-1">
                                <v-select
                                label="Payment method :"
                                :items="paymentMethodOptions"
                                v-model="paymentInfo.payment_method"
                                @change="checkStatus">
                                </v-select>
                            </div>
                            <div class="my-1">
                                <v-select
                                v-if="this.$store.getters.getRole=='vision_admin'"
                                label="Payment status :"
                                :items="paymentStatusOptions"
                                v-model="paymentInfo.status">

                                </v-select>
                            </div>
                            <div class="my-1">
                                <p class="d-flex justify-start mt-3">
                                    Payment date :
                                </p>
                                <div class="d-flex justify-start" >
                                    <v-date-picker
                                    v-model="paymentInfo.payment_at"
                                    :landscape="true"
                                    :reactive="true"></v-date-picker>
                                </div>
                            </div>
                            <div class="mt-3">
                                <p>Remark</p>
                                <mavon-editor
                                style="z-index:0;height:auto"
                                defaultOpen = "edit"
                                :toolbarsFlag = "false"
                                :toolbars="markdownToolbar"
                                language="en"
                                v-model="paymentInfo.remark"
                                placeholder="Start typing your remark...">

                                </mavon-editor>
                            </div>
                        </v-col>
                            <div class="d-flex justify-end ">
                                <v-btn color="primary" plain>
                                    Clear      
                                </v-btn>
                                <v-btn @click = "submit"
                                :loading="this.api.isLoading"
                                color="primary">
                                    Confirm
                                </v-btn>
                            </div>
                    </v-container>
                </v-card> -->
                <v-card
                v-if="this.$store.getters.getRole=='salesperson'">
                    <v-container>
                        <div class="d-flex flex-column">
                            <p class="d-flex align-content-center flex-wrap">Upload attachment</p>
                            <v-file-input
                            color="primary"
                            v-model="attachments"
                            outlined
                            multiple
                            label="Please choose the files"
                            counter
                            :show-size="1000">
                                <template v-slot:selection="{index,text}">
                                    <v-chip
                                    v-if="index<2"
                                    color="deep-purple accent-4"
                                    dark
                                    label
                                    small>
                                        {{ text }}
                                    </v-chip>
                                    <span
                                    v-else-if="index===2"
                                    class="text-overline grey--text text--darken-3">
                                     +{{ files.length -2 }} Files(s)
                                    </span>
                                </template>
                            </v-file-input>
                        </div>
                        <div class="d-flex justify-end">
                            <v-btn color="primary" @click="submit">
                                Upload
                            </v-btn>
                        </div>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
// import mavonEditor from 'mavon-editor';
export default{
    components:{
        // 'mavon-editor':mavonEditor.mavonEditor,
    },
    props:[
        'company'
    ],
    data:()=>(
        {
            attachments:[],
            paymentInfo:{
                payment_method:"",
                total_amount:"",
                status:"",
                payment_at:"",
                remark:"",
            },
            markdownToolbar:{
                preview:false,
            },
            isSaving:false,
            isOnlineBanking:false,
            isCreditCard:false,
            isShowCalendar :false,
            paymentMethod:'',
            paymentMethodOptions:['Stripe','Bank transfer','Ipay88 No installment','Ipay88 6 months installment','Ipay88 12 months installment','Ipay88 E-wallet','Cheque'],
            paymentStatusOptions:['Pending','Confirm','Rejected'],
            bankType:'',
            bankAccountNumber:'',
            bankTypeOptions:[
                'AmBank','Affin bank','Alias Bank',' Bank Islam', 'Cimb Bank','City bank','Public bank','Maybank','Hong leong bank','Finexus service bank'
            ],
            confirmAt:null,
            isFirstLoading:true,
            api:{
                isLoading:true,
                isError:false,
                isSuccesful:false,
                error:null,
                url:null,
            }
        }
    ),
    created(){
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
            this.api.isSuccesful = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isError = true;
            this.api.isLoading = false;
            this.api.isSuccesful = false;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.status != "success")
            {
                this.api.isError = true;
                return 0;
            }

            if(resp.class=="Form"){
                if(!resp.data){
                    let date = new Date();
                    let day = date.getDate();
                    let month = date.getMonth()+1;
                    let year = date.getFullYear();
                    this.paymentInfo = {
                        payment_method:"",
                        total_amount:"",
                        status:"",
                        payment_at:year+'-'+month+'-'+day,
                    }
                    this.api.isLoading = false;
                    this.api.isSuccesful = true;
                    this.isSaving = true;
                    return 0
                }
                this.paymentInfo = JSON.parse(resp.data);
                this.paymentInfo.payment_at = this.paymentInfo.payment_at.split(' ')[0].replaceAll('/','-');
                this.api.isLoading = false;
                this.api.isSuccesful = true;
            }
            if(this.api.url == process.env.VUE_APP_SERVER_API+"/form/"+this.company.company_id)
            {
                return 1;
            }
            if(resp.class=='Company')
            {
                this.$store.commit('updateCompany',resp.data[0]);
            }
            // if(this.api.url ==process.env.VUE_APP_SERVER_API+"/companies/getsubscription/"+this.company.company_id+"?status=All")
            // {
               
            // }
            return 1;
        }
    },

    mounted()
    {
        this.fetch().then(()=>{
            this.isFirstLoading = false;
        });

    },
    methods:{
        fetch(){
            this.api.url = process.env.VUE_APP_SERVER_API+"/form/latest/"+this.company.company_id+"?formType=Payment";
            this.api.method = "GET";
            return this.$api.fetch(this.api);
        },
        fetchCompany()
        {
            this.api.method = "get";
            this.api.url= process.env.VUE_APP_SERVER_API+"/companies/getsubscription/"+this.company.company_id+"?status=All";
            this.$api.fetch(this.api).then(()=>{
                this.$router.push({name:'PageCompaniesDetail',params:{'id':this.company.company_id}});
            });
        },
        save()
        {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.params ={
                'formType':'Payment',
                'paymentMethod':(this.paymentInfo.payment_method)?this.paymentInfo.payment_method:null,
                'status':(this.paymentInfo.status)?this.paymentInfo.status:null,
                'totalAmount':(this.paymentInfo.total_amount!=null)?this.paymentInfo.total_amount:null,
                'userEmail':this.$store.getters.getEmail,
                'paymentAt':this.paymentInfo.payment_at,
                'remark':this.paymentInfo.remark,
            }
            tempApi.url = process.env.VUE_APP_SERVER_API+"/form/"+this.company.company_id
            return tempApi;
        },
        update()
        {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.params ={
                'formType':'Payment',
                'paymentMethod':(this.paymentInfo.payment_method)?this.paymentInfo.payment_method:null,
                'status':(this.paymentInfo.status)?this.paymentInfo.status:this.paymentInfo.status,
                'totalAmount':(this.paymentInfo.total_amount!=null)?this.paymentInfo.total_amount:null,
                // 'userEmail':this.$store.getters.getEmail,
            }
            tempApi.url = process.env.VUE_APP_SERVER_API+"/form/"+this.company.company_id
            return tempApi;
        },
        readFile()
        {
            // const reader = new FileReader();
            // this.files.forEach((file,f) => {

            // });
        },
        updateCompany()
        {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/companies/getsubscription/"+this.company.company_id+"?status=All";
            return tempApi;
        },
        submit(){
            
            if(this.$store.getters.getRole=='salesperson')
            {
                return 1;
            }
            let firstApi = this.save();
            let secondApi = this.updateCompany();
            this.$axios.all([
                this.$api.fetch(firstApi),
                this.$api.fetch(secondApi),
            ]).then(()=>{
                this.$router.push({name:'PageCompaniesDetail',params:{'id':this.company.company_id}});
            })
        },
        checkStatus(){
            this.isShowCalendar = true;
            this.isOnlineBanking = false;
            this.isCreditCard = false;
            if(this.paymentMethod=='Online banking'){
                this.isOnlineBanking = true;
            }
            if(this.paymentMethod =='Credit card'){
                this.isCreditCard = true;
            }
        }
    }
}
</script>
